import "./study-resources.css";
import { useState } from "react";
import { LanguageContext } from "../../contexts/language-context";
import { useContext } from "react";
import parse from "html-react-parser";

const LongCovidSummary: React.FC = () => {
  const [meAndCfsCollapsed, setMeAndCfsCollapsed] = useState(true);
  const [potsCollapsed, setPotsCollapsed] = useState(true);
  const {long_covid_summary: lang, download, step} = useContext(LanguageContext);

  function toggleMeAndCfsCollapsed() {
    setMeAndCfsCollapsed(!meAndCfsCollapsed);
  }

  function togglePotsCollapsed() {
    setPotsCollapsed(!potsCollapsed);
  }

  return (
    <div className="study-resources long-covid-summary">
      <div className="header">
        <h2>{lang.title}</h2>
        <a
          className="btn-primary"
          href={download.link}
          target="_blank"
          rel="noreferrer"
        >
          {download.text}
        </a>
      </div>
      <section className={meAndCfsCollapsed ? "collapsed" : "expanded"}>
        <div className="section-header" onClick={toggleMeAndCfsCollapsed}>
          <div>
            <label>
              {lang.sections[0].title}
            </label>
          </div>
          <div className={"plus-minus " + (meAndCfsCollapsed ? "collapsed" : "expanded")}>
          </div>
        </div>
        <div className="expand-container">
          <div className={"section-body " + (meAndCfsCollapsed ? "collapsed" : "expanded")}>
            <div className="pacing">
              <h4>{lang.sections[0].subtitle}</h4>
              <hr />
            </div>
            <div className="grid small">
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/Baseline.svg"}
                  alt="Baseline"
                />
              </div>
              <div>
                {step} 1:
                <br />
                {lang.sections[0].steps[0]}
              </div>
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/Note.svg"}
                  alt="Note"
                />
              </div>
              <div>
                {step} 2:
                <br />
                {lang.sections[0].steps[1]}
              </div>
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/Priority.svg"}
                  alt="Priority"
                />
              </div>
              <div>
                {step} 3:
                <br />
                {lang.sections[0].steps[2]}
              </div>
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/Happy.svg"}
                  alt="Happy"
                />
              </div>
              <div>
                {step} 4:
                <br />
                {lang.sections[0].steps[3]}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={potsCollapsed ? "collapsed" : "expanded"}>
        <div className="section-header" onClick={togglePotsCollapsed}>
          <div>
            <label>
                {lang.sections[1].title}
            </label>
          </div>
          <div className={"plus-minus " + (potsCollapsed ? "collapsed" : "expanded")}>
          </div>
        </div>
        <div className="expand-container">
          <div className={"section-body " + (potsCollapsed ? "collapsed" : "expanded")}>
            <p>{lang.sections[1].steps[0]}</p>
            <img src={process.env.PUBLIC_URL + "/assets/Salt.svg"} alt="Salt" />
            <p>
              {lang.sections[1].steps[1]}
            </p>
            <img
              src={process.env.PUBLIC_URL + "/assets/Exercise.svg"}
              alt="Exercise"
            />
            <p>
              {parse(lang.sections[1].footer)}
            </p>
            <br />
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default LongCovidSummary;
